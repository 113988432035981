import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String, loaded: Boolean }

  click() {
    if (this.loadedValue) {
      const el = document.querySelector('#aside');
      coreui.Sidebar.getInstance(el).show();
    } else {
      get(this.urlValue).then((response) => {
        if (response.ok) {
          const result_text = response.text;
          result_text.then(result => {
            const el = document.querySelector('#aside');
            el.innerHTML = result;
            coreui.Sidebar.getInstance(el).show();
          });
        }
      });
      this.loadedValue = true;
    }
  }
}
