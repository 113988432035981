/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)
global.coreui = require("@coreui/coreui-pro")
import "simplebar";

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

if (document.body.classList.contains('dark-theme')) {
  var element =  document.getElementById('btn-dark-theme');
  if (typeof(element) != 'undefined' && element != null) {
    document.getElementById('btn-dark-theme').checked = true;
  }
} else {
  var element =  document.getElementById('btn-light-theme');
  if (typeof(element) != 'undefined' && element != null) {
    document.getElementById('btn-light-theme').checked = true;
  }
}

function handleThemeChange(src) {
  var event = document.createEvent('Event');
  event.initEvent('themeChange', true, true);

  if (src.value === 'light' ) {
    document.body.classList.remove('dark-theme');
  }
  if (src.value === 'dark' ) {
    document.body.classList.add('dark-theme');
  }
  document.body.dispatchEvent(event);
}

global.handleThemeChange = handleThemeChange;
